import React from 'react';
import EventProvider from './EventProvider';
import SearchProvider from './SearchProvider';
import DelayProvider from './DelayProvider';
import ThemeProvider from './ThemeProvider';
import '../styles/index.scss';

function Provider({ children }) {
  return (
    <ThemeProvider>
      <EventProvider>
        <SearchProvider>
          <DelayProvider>{children}</DelayProvider>
        </SearchProvider>
      </EventProvider>
    </ThemeProvider>
  );
}

export default Provider;
