import React, { useState } from 'react';
import { ThemeProvider as Theme } from 'styled-components';

export const ThemeContext = React.createContext();

export const device = {
  smallMobile: 'screen and (max-width: 324px)',
  mobile: 'screen and (max-width: 767px)',
  tablet: 'screen and (max-width: 1024px)',
  desktop: 'screen and (min-width: 1025px)',
  monitor: 'screen and (min-width: 1600px)',
};

const themeStyling = {
  colors: {
    orange: '#cc770e',
    theme: 'linear-gradient(90deg, #0d0325 0%, #0a041a 100%)',
  },
};

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(themeStyling);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <Theme theme={theme}>{children}</Theme>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
