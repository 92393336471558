import commercials from '../utils/commercials';

export const initialState = {
  events: null,
  isSearching: false,
  totalPages: 0,
  totalEvents: 0,
  noEvents: false,
};

const reducer = (state, action) => {
  if (action.isSearching) {
    // Is searching
    return { events: state.events, isSearching: true };
  }

  if (!action.events && !action.isSearching && !action.noEvents) {
    // Reset search - clicking on logo
    return { events: null, isSearching: false };
  }

  if (!action.events.length) {
    // No events founded from frontpage
    return { events: null, isSearching: false, noEvents: true };
  }

  if (action.events && action.noEvents) {
    // No events founded but going back to earlier succeded search
    return { events: state.events, isSearching: false, noEvents: true };
  }

  if (action.noEvents === null) {
    // Going back to earlier events, after showing none events message
    return { events: state.events, isSearching: false, noEvents: false };
  }

  const withCommercials = [];
  if (action.events && action.events.length >= 20) {
    action.events.forEach((event, key) => {
      if (key === 5 || key === 10 || key === 15 || key === 20 || key === 25) {
        withCommercials.push(event, commercials[key / 5 - 1]);
        return;
      }

      withCommercials.push(event);
    });
  }

  return {
    totalPages: action.totalPages,
    events: withCommercials.length ? withCommercials : action.events,
    isSearching: false,
  };
};

export default reducer;
