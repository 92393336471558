import React, { useState } from 'react';

export const SearchContext = React.createContext();

function SearchProvider({ children }) {
  const [keyword, setKeyword] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [category, setCategory] = useState(null);
  const [zipCode, setZipCode] = useState('');
  return (
    <SearchContext.Provider
      value={{
        keyword,
        setKeyword,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        category,
        setCategory,
        zipCode,
        setZipCode,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

export default SearchProvider;
