import React, { useState } from 'react';

export const DelayContext = React.createContext();

function DelayProvider({ children }) {
  const [delay, setDelay] = useState(false);
  return (
    <DelayContext.Provider value={{ delay, setDelay }}>
      {children}
    </DelayContext.Provider>
  );
}

export default DelayProvider;
