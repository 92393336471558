const commercials = [
  {
    isCommercial: true,
    title: 'Social Dining på Restaurant Basso',
    image:
      'https://static.truestory.com/media/1012/social_dining_pa_restaurant_basso-thumbnail.jpg',
    url:
      'https://www.partner-ads.com/dk/klikbanner.php?bannerid=67095&partnerid=28634&htmlurl=https://truestory.dk/oplev/social-dining-restaurant-basso',
  },
  {
    isCommercial: true,
    title: "Morgenmad & massage hos Ni'mat",
    image:
      'https://static.truestory.com/media/1038/morgenmad-massage-nimat-thumbnail-938x728.jpg',
    url:
      'https://www.partner-ads.com/dk/klikbanner.php?bannerid=67095&partnerid=28634&htmlurl=https://truestory.dk/oplev/morgenmad-massage-nimat',
  },
  {
    isCommercial: true,
    title: 'Casino ophold på Marienlyst Strandhotel',
    image:
      'https://static.truestory.com/media/1046/casino-ophold-pa-marienlyst-strandhotel-thumbnail.jpg',
    url:
      'https://www.partner-ads.com/dk/klikbanner.php?bannerid=67095&partnerid=28634&htmlurl=https://truestory.dk/oplev/casino-ophold-marienlyst-strandhotel',
  },
  {
    isCommercial: true,
    title: 'Luksus spaophold på HimmerLand',
    image:
      'https://static.truestory.com/media/1010/luksus-spaophold-himmerland-spa-golf-resort-thumbnail-938x728.jpg',
    url:
      'https://www.partner-ads.com/dk/klikbanner.php?bannerid=67095&partnerid=28634&htmlurl=https://truestory.dk/oplev/luksus-spaophold-himmerland-golf-spa-resort',
  },
  {
    isCommercial: true,
    title: 'Cocktailkursus hos Gedulgt',
    image:
      'https://static.truestory.com/media/1579/cocktailkursus-hos-gedulgt-thumbnail.jpg',
    url:
      'https://www.partner-ads.com/dk/klikbanner.php?bannerid=67095&partnerid=28634&htmlurl=https://truestory.dk/oplev/cocktailkursus-gedulgt',
  },
];

export default commercials;
