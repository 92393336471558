function handleCity(city) {
  switch (city) {
    case 'københavn':
      return 'københavn v,københavn k';
    case 'aarhus':
      return 'aarhus c, aarhus n, aarhus v, tilst, brabrand';
    case 'odense':
      return 'odense c, odense sø, odense sv';
    case 'aalborg':
      return 'aalborg';
    case 'esbjerg':
      return 'esbjerg, esbjerg n, esbjerg ø,';
    case 'randers':
      return 'randers';
    default:
      return city;
  }
}

export default handleCity;
