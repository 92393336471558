/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React, { useContext } from 'react';
import Provider from './src/provider';
import Searching from './src/components/Searching';
import { EventsContext } from './src/provider/EventProvider';
import { DelayContext } from './src/provider/DelayProvider';
import Sidebar from './src/components/Sidebar';
import usePrevious from './src/hooks/usePrevious';

const SearchLayer = ({ children }) => {
  const { state } = useContext(EventsContext);
  const { delay, setDelay } = useContext(DelayContext);
  const previousEvents = usePrevious(state.events);

  return (
    <React.Fragment>
      <div
        className={`${
          (state.isSearching || delay) && previousEvents !== undefined
            ? 'searching-blur'
            : ''
        }`}
      >
        {children}
      </div>
      <Searching
        delay={delay}
        setDelay={setDelay}
        active={state.isSearching || state.noEvents}
      />
      <Sidebar delay={delay} />
    </React.Fragment>
  );
};

export const wrapRootElement = ({ element }) => (
  <Provider>
    <SearchLayer>{element}</SearchLayer>
  </Provider>
);
