import React from 'react';

function Keyword({ handleKey, keyword, setKeyword }) {
  return (
    <div className="keyword-selector">
      <input
        onKeyPress={handleKey && ((e) => handleKey(e))}
        className="category-field"
        placeholder="Søg event"
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
      />
      {keyword && (
        <button
          className="keyword-selector-clear"
          onClick={() => setKeyword('')}
        ></button>
      )}
    </div>
  );
}

export default Keyword;
