import { useState, useEffect } from 'react';

const useWindowsize = () => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);

      if (window.innerWidth >= 1025) {
        setHeight(document.documentElement.clientHeight);
      } else if (window.innerWidth <= 1024 && window.innerWidth >= 768) {
        setHeight(document.documentElement.clientHeight - 80);
      } else {
        setHeight(document.documentElement.clientHeight - 66);
      }
    };

    window.addEventListener('resize', () => handleResize());
    window.addEventListener('load', handleResize());
    return () => {
      window.removeEventListener('resize', () => handleResize());
      window.removeEventListener('load', handleResize());
    };
  });

  return [width, height];
};

export default useWindowsize;
