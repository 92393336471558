import React from 'react';

function ZipCode({ handleKey, zipCode, setZipCode, placeholderText }) {
  return (
    <div className="zipCode-selector">
      <input
        onKeyPress={handleKey && ((e) => handleKey(e))}
        className="category-field"
        placeholder={placeholderText || 'Søg events i valgte postnummer'}
        value={zipCode}
        onChange={(e) => setZipCode(e.target.value)}
      />
      {zipCode && (
        <button
          className="zipCode-selector-clear"
          onClick={() => setZipCode('')}
        />
      )}
    </div>
  );
}

export default ZipCode;
