import { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import handleCategory from '../utils/handleCategory';
import handleCity from '../utils/handleCity';
import dateFormat from '../utils/dateformat';
import { EventsContext } from '../provider/EventProvider';

let events = [];
let url = `https://static8-api.herokuapp.com/zooticket/filterEvents?size=20&page=0&sort=date,asc&startDateTime=${dateFormat(
  new Date()
)}`;
let latestValidUrl = url;

const useSearch = (keyword, startDate, endDate, zipCode, category, city) => {
  const { dispatch } = useContext(EventsContext) || { dispatch: null };
  const delayTimeout = 1300;
  const totalPages = useStaticQuery(graphql`
    {
      allSitePage(filter: { path: { regex: "/(events)/" } }) {
        totalCount
      }
    }
  `).allSitePage.totalCount;

  const handleClick = () => {
    // Add optionals to filter search url
    let filtered = `https://static8-api.herokuapp.com/zooticket/filterEvents?size=20&page=0&sort=date,asc&startDateTime=${dateFormat(
      startDate || new Date()
    )}`;
    if (keyword) filtered += `&keyword=${keyword}`;
    if (endDate) {
      // Necessary to add one day, to include the events on the enddate itself from Ticketmaster
      const addOneDay = new Date(endDate);
      addOneDay.setDate(addOneDay.getDate() + 1);
      filtered += `&endDateTime=${dateFormat(addOneDay)}`;
    }
    if (zipCode) {
      // Search for citynames
      if (zipCode.match(/[A-Za-z]/g) && !keyword) {
        filtered += `&keyword=${zipCode}`;
      } else {
        filtered += `&postalCode=${zipCode}`;
      }
    }
    if (category) filtered += `&genreId=${handleCategory(category)}`;

    if (city) filtered += `&city=${handleCity(city)}`;

    // Avoid same search
    if (url === filtered && events.length) return;
    url = filtered;
    window.scrollTo(0, 0);

    // Reset for each click
    // events = [];
    dispatch({ events: events.length ? events : null, isSearching: true });
    handleSearch(url, delayTimeout);
  };

  const handleScroll = (pageNumber) => {
    if (latestValidUrl !== url) {
      url = latestValidUrl;
    }
    url = url.replace(/&page=\d+/g, `&page=${pageNumber}`);
    handleSearch(url);
  };

  const handleSearch = (link, clicked) => {
    fetch(link)
      .then((res) => res.json())
      .then((e) => {
        if (!e.length) {
          // No events founded
          setTimeout(() => {
            dispatch({
              events: events.length ? events : e,
              isSearching: false,
              noEvents: clicked || false,
            });
          }, delayTimeout);
          return;
        }

        if (clicked) {
          // Timeout made cause of better UX
          latestValidUrl = link;
          events = [];
          events = e;
          setTimeout(() => {
            dispatch({ events, totalPages, isSearching: false });
          }, delayTimeout);
        } else {
          // on scroll
          latestValidUrl = link;
          events = [...events, ...e];
          dispatch({ events, isSearching: false });
        }
      })
      .catch((err) => console.log(err));
  };

  const resetSearch = () => {
    url = `https://static8-api.herokuapp.com/zooticket/filterEvents?size=20&page=0&sort=date,asc&startDateTime=${dateFormat(
      new Date()
    )}`;

    events = [];
    dispatch({ events: null, isSearching: false });
  };

  return [handleClick, handleScroll, resetSearch, url];
};

export default useSearch;
